import * as ExcelJS from 'exceljs'
import { analyticsGetQuery, buildCompleteQuery } from '~/services/analytics'
import { formatValueForExport } from '~/services/format'
import {
  getColumns,
  getListMeasureTranslation,
  getMeasureFromTitle
} from '~/services/explore'
import { DATA_EXPORT_TYPE } from '~/types/dataExport'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import { useScopeStore } from '~/stores/scope'
import type { TableData } from '~/types/table'
import type {
  Executable,
  ListExplorerQuery,
  ResultSetItem
} from '~/types/query'
import { QueryTimeType } from '~/types/analytics'
import { useFiltersStore } from '~/stores/filters'

export const exportListData = async (
  query: Executable<ListExplorerQuery>,
  includeMeasureAsColumn: boolean,
  exportType: DATA_EXPORT_TYPE
) => {
  const { allAppliedFilters } = useFiltersStore()
  const { selectedPeriod } = useTimeDimensionStore()
  const completeQuery = buildCompleteQuery(
    query,
    {
      type: QueryTimeType.PERIOD
    },
    selectedPeriod,
    allAppliedFilters
  )

  const queryResults = await analyticsGetQuery(
    completeQuery,
    useScopeStore().scopeType
  )
  const items: ResultSetItem[] = queryResults.results[0]!.data

  const columns = getColumns(queryResults, includeMeasureAsColumn)

  const data = items.map(item =>
    columns.map(column =>
      formatValueForExport(item[column.name]!, column.format, exportType)
    )
  )

  const columnHeaders = columns.map(column => column.value)

  const blob =
    exportType === DATA_EXPORT_TYPE.CSV
      ? buildCsvDataBlob(data, columnHeaders)
      : await buildXlsxDataBlob(data, columnHeaders)

  const fileName = `reflect_list_${getExportFileName(query.measures)}.${
    exportType === DATA_EXPORT_TYPE.CSV ? 'csv' : 'xlsx'
  }`
  downloadFile(blob, fileName)
}

const buildCsvDataBlob = (data: string[][], columns: string[]) => {
  const dataString = data.map(row => row.join(',')).join('\n')
  const columnHeadersString = columns.join(',')
  const csvString = columnHeadersString + '\n' + dataString
  return new Blob([csvString], { type: 'text/csv' })
}

const buildXlsxDataBlob = async (data: string[][], columns: string[]) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet()
  worksheet.addRows([columns, ...data])

  const buffer = await workbook.xlsx.writeBuffer()
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
}

const getExportFileName = (measures: string[]) => {
  const selectedPeriod = useTimeDimensionStore().selectedPeriod
  const formattedMeasures = measures
    .map(measureTitle =>
      getListMeasureTranslation(getMeasureFromTitle(measureTitle))
    )
    .join('_')
    .replaceAll(' ', '_')

  return `${formattedMeasures}_${selectedPeriod.dateRange[0]}_${selectedPeriod.dateRange[1]}`
}

export const exportKpiData = async (
  tableData: TableData,
  exportType: DATA_EXPORT_TYPE,
  measureTitle: string
) => {
  const data = tableData.bodyRows.map(row =>
    row.map(cell => cell.content ?? '')
  )

  const columns = tableData.headerRows[0]?.map(cell => cell.content ?? '') ?? []

  const blob =
    exportType === DATA_EXPORT_TYPE.CSV
      ? buildCsvDataBlob(data, columns)
      : await buildXlsxDataBlob(data, columns)

  const fileName = `reflect_kpi_${getExportFileName([measureTitle])}.${
    exportType === DATA_EXPORT_TYPE.CSV ? 'csv' : 'xlsx'
  }`
  downloadFile(blob, fileName)
}

const downloadFile = (blob: Blob, fileName: string) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.download = fileName
  downloadLink.click()
}
